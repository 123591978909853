import { template as template_8429cb8b8c6d4f4785ce58d10fb75c18 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_8429cb8b8c6d4f4785ce58d10fb75c18(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
